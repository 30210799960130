<template>
    <v-navigation-drawer permanent width="100%" class="pt-5">
        <v-list-item>
            <v-list-item-avatar>
                <v-img max-width="100" contain src="@/assets/images/logo.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="text-h6">PONCHES FINCA</v-list-item-title>
                <v-list-item-subtitle>{{ username }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
		<v-list nav dense>
			<v-list-item v-for="(r, i) in routes" :key="`route-${i}`" :to="r.to" router exact>
				<v-list-item-action>
					<v-icon>{{ r.icon }}</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title v-text="r.title"></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="logout">
				<v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
				<v-list-item-title>Salir</v-list-item-title>
			</v-list-item>
		</v-list>
    </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'Sidebar',
	data: () => ({
		routes: [
			{ title: 'Ponches', to: '/', icon: 'mdi-home' },
			{ title: 'Ponches por día', to: '/admin/timestamps', icon: 'mdi-table' },
			{ title: 'Ponches Manuales', to: '/admin/manual-timestamps', icon: 'mdi-table-clock' },
			{ title: 'Empleados', to: '/admin/employees', icon: 'mdi-account-circle' },
			// { title: 'Crear Incidencias', to: '/admin/incidentss', icon: 'mdi-alert-plus' },
			{ title: 'Incidencias', to: '/admin/incidents', icon: 'mdi-alert-circle' },
		]
	}),
	methods: {
        ...mapActions(['logout']),
	},
    computed : {
		...mapGetters(['username']),
	},
};
</script>