import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token   	: localStorage.getItem('ponches-token') || '',
		user    	: JSON.parse(localStorage.getItem('ponches-user')) || {},
		employees	: JSON.parse(localStorage.getItem('ponches-employees')) || []
	},
	mutations: {
		auth_success(state, { token, user, employees }) {
			state.token 	= token
			state.user 		= user
			state.employees	= employees
			localStorage.setItem('ponches-token', token)
			localStorage.setItem('ponches-user', JSON.stringify(user))
			localStorage.setItem('ponches-employees', JSON.stringify(employees))
			axios.defaults.headers.common['Authorization'] = token
		},
		logout(state) {
			state.token 	= ''
			state.user 		= {}
			state.employees = []
			localStorage.removeItem('ponches-token')
			localStorage.removeItem('ponches-user')
			localStorage.removeItem('ponches-employees')
			delete axios.defaults.headers.common['Authorization']
		},
		update_employees(state, { employees }) {
			state.employees = employees
			localStorage.setItem('ponches-employees', JSON.stringify(employees))
		}
	},
	actions: {
		login({ commit }, user){
			return new Promise((resolve, reject) => {
				axios( {url: '/auth/login', data: user, method: 'POST' }).then((resp) => {
					const { token, user, employees } = resp.data
					commit('auth_success', { token, user, employees })
					router.push('/admin/timestamps')
					resolve(resp)
				}).catch((err) => {
					reject(err)
				})
			})
		},
		logout({ commit }){
			return new Promise(( resolve ) => {
				commit('logout')
				router.push('/')
				resolve()
			})
		},
		upsert_employee({ commit }, employee){
			return new Promise((resolve, reject) => {
				axios( {url: '/admin/employees', data: employee, method: 'POST' }).then((resp) => {
					commit('update_employees', { employees: resp.data.employees })
					resolve(resp)
				}).catch((err) => {
					reject(err)
				})
			})
		},
	},
	getters : {
		is_logged_in	: (state) => !!state.token,
		auth_status		: (state) => state.status,
		user			: (state) => state.user,
		username		: (state) => state.user.username,
		area			: (state) => state.user.area,
		employees		: (state) => state.employees,
        areas			: (state) => ((state.user.area === 'all') ? ['Amelia', 'Ramon', 'Rufina'] : state.user.area),
		is_admin		: (state) => state.user.area === 'all'
	}
})
