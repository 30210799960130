import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import JsonCSV from 'vue-json-csv'
 
require('@/assets/styles/style.css')
Axios.defaults.baseURL      = 'https://ponches-finca.prsupplies.com/api'
Vue.config.productionTip    = false
Vue.prototype.$http         = Axios
Vue.component('downloadCsv', JsonCSV)

const token                 = localStorage.getItem('ponches-token')
if (token) Vue.prototype.$http.defaults.headers.common['Authorization'] = token

new Vue({ router, store, vuetify, render: h => h(App) }).$mount('#app')
