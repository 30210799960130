import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: () => import('@/views/Home.vue'),
		meta: { title: 'Ponches finca' }
	}, { 
		path		: '/login',
		name		: 'login',
		component: () => import('@/views/Login.vue'),
		meta		: { guest: true, title: 'Login' }
	}, { 
		path: '/admin', redirect: '/login' 
	}, {
		path: '/admin/manual-timestamps',
		name: 'manual-timestamps',
		component: () => import('@/views/ManualTimestamps.vue'),
		meta: { requires_auth: true, title: 'Admin - Ponches manuales' }
	}, {
		path: '/admin/timestamps',
		name: 'timestamps',
		component: () => import('@/views/Timestamps.vue'),
		meta: { requires_auth: true, title: 'Admin - Ponches' }
	}, {
		path: '/admin/employees',
		name: 'employees',
		component: () => import('@/views/Employees.vue'),
		meta: { requires_auth: true, title: 'Admin - Empleados' }
	}, {
		path: '/admin/incidents',
		name: 'incidents',
		component: () => import('@/views/Incidents.vue'),
		meta: { requires_auth: true, title: 'Admin - Incidencias' }
	}, {
		path: '*',
		name: '404',
		component: () => import('@/views/Home.vue'),
		meta: { title: '404' }
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some((record) => record.meta.requires_auth)) {
        if (localStorage.getItem('ponches-token') == null) {
            next({ path: '/login', params: { nextUrl: to.fullPath } })
        } else {
            let user = JSON.parse(localStorage.getItem('ponches-user'))
            if(to.matched.some((record) => record.meta.requires_admin)) {
                if(user.requires_admin){
                    next()
                } else{
                    next({ name: 'timestamps'})
                }
            } else {
                next()
            }
        }
    } else if(to.matched.some((record) => record.meta.guest)) {
        if(localStorage.getItem('ponches-token') == null){
            next()
        } else{
            next({ name: 'timestamps'})
        }
    } else {
        next()
    }
})

router.afterEach((to) => {
    Vue.nextTick(() => { document.title = to.meta.title || 'Ponches finca' })
});

export default router
