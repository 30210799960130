<template>
	<v-app>
		<v-main>
			<v-container fluid fill-height class="py-0">
				<v-row class="h-100">
					<v-col v-if="is_logged_in" cols="12" md="2" class="pa-0" :class="height">
						<sidebar></sidebar>
					</v-col>
					<v-col cols="12" class="pa-7" :md="is_logged_in ? 10 : 12">
						<router-view></router-view>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
		<alert></alert>
	</v-app>
</template>

<script>

import Sidebar from '@/components/Sidebar.vue'
import Alert from '@/components/Alert.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'App',
	components: { Sidebar, Alert },
	data: () => ({}),
	created() {
		this.$http.interceptors.response.use((response) => {
			const { status, message } = response.data
			if (status && message) this.$root.$emit('alert', { status, message })
			return response
		}, (err) => {
			var message = 'Error interno en el servidor'
			if (err.response && err.response.status === 401) {
				this.logout()
				message = 'Su sesión ha expirado'
			} else if (err.response && err.response.data && err.response.data.message) {
				var message = err.response.data.message 
			} else {
				var message = 'Error interno en el servidor'
			}

			this.$root.$emit('alert', { status: 'error', message })
			return Promise.reject(err)
		})
	},
	methods: {
		...mapActions(['logout']),
	},
	computed: {
		...mapGetters(['is_logged_in']),
		height () {
			switch (this.$vuetify.breakpoint.name) {
			case 'md':
			case 'lg':
			case 'xl': return 'h-100'
			default  : return '' 
			}
		}
	}
}
</script>