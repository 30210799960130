<template>
    <v-snackbar v-model="snackbar" :timeout="-1" multi-line color="#333" top>
        <span class="mr-3"><v-icon x-large :color="color">{{ icon }}</v-icon></span>
        <div class="headline font-weight-bold">{{ message }}</div>
    </v-snackbar>
</template>

<script>
export default {
	name: 'Alert',
	data: () => ({
        snackbar    : false,
        message     : '',
        status      : '',
        timeout     : null,
	}),
	mounted() {
        /** 
         *  alert event listener
         *  @param {Object} alert - alert obj
         */
        this.$root.$on('alert', ({status, message}) => {
            this.message = message
            this.status = status
            this.snackbar = true
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => { this.snackbar = false }, 4000)
        })

    },
    beforeDestroy() { this.$root.$off('alert') },
	computed: {
        /** 
         *  alert icon
         *  @return {String} - icon for status
        */
        icon()  {
            switch (this.status) {
                case 'clock-in'     :
                case 'success'      : return 'mdi-check-circle'
                case 'error'        : return 'mdi-close-circle'
                case 'clock-out'    : return 'mdi-logout'
                default             : return 'mdi-exclamation-circle';
            } 
        },
        /** 
         *  alert color
         *  @return {String} - color for status
        */
        color() {
            switch (this.status) {
                case 'clock-in'     :
                case 'success'      : return 'success'
                case 'error'        : return 'error'
                case 'clock-out'    : return 'orange lighten-1'
                default             : return 'orange';
            }
        }
	}
}
</script>